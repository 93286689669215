
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import TypeDialog from '@/components/typeDialog/Index.vue'
import { Page } from '../../types/common'
import { KeepAlive } from '@/utils/decorators'
import { Detail } from '../../types/insectPests'
@Component({
  name: 'InsectPestsList',
  components: { ImportData, TypeDialog }
})
@KeepAlive
export default class extends Vue {
  private typeList = []
  private searchData = {
    pestsName: '',
    pestsType: ''
  }

  private isLoading = false
  private list: Array<Detail> = []
  private total = 0
  private page = 1
  private size = 10
  private isShowImport = false
  private typeShow = false

  created () {
    this.getInsectPestsType()
    this.getTableList()
  }

  // 获取虫害类别
  getInsectPestsType () {
    this.$axios.get(this.$apis.common.thinktankTypeList, {
      dicType: 'pestsType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  //   获取表格数据
  getTableList () {
    this.isLoading = true
    this.$axios.get<Page<Detail>>(this.$apis.insectPests.selectPestsByPage, {
      ...this.searchData,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.list = res.list || []
      this.total = res.total || 0
      this.isLoading = false
    }).finally(() => {
      this.isLoading = false
    })
  }

  //   搜索
  onSearch () {
    this.page = 1
    this.getTableList()
  }

  //   新增
  onAdd () {
    this.$router.push('/insectPests/add')
  }

  //   查看
  onDetail (id: string) {
    this.$router.push({ name: 'insectPestsDetail', params: { id } })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({ name: 'insectPestsUpdate', params: { id } })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.insectPests.deleteDataPests, {
        pestsId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.getTableList()
      })
    })
  }
}
